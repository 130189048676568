var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Productdetails_box"},[_c('div',{staticClass:"goout",on:{"click":_vm.goout}},[_c('i',{staticClass:"el-icon-back"}),_vm._v(" Find Products ")]),_c('div',{staticClass:"Productdetails_head"},[_c('div',{staticClass:"Productdetails_headleft"},[_c('div',{staticClass:"block"},[_c('el-carousel',{staticStyle:{"width":"300px","border":"1px solid rgb(233, 233, 233)","border-radius":"5px"},attrs:{"indicator-position":"none"}},_vm._l((_vm.info.images),function(item,index){return _c('el-carousel-item',{key:index},[_c('el-image',{staticStyle:{"width":"300px","height":"300px","border-radius":"5px"},attrs:{"src":item,"fit":"cover"}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_vm._v(" fail to load ")])])],1)}),1)],1)]),_c('div',{staticClass:"Productdetails_headright"},[_c('div',{staticClass:"Productdetails_headrighttit"},[_vm._v(" "+_vm._s(_vm.info.name)+" ")]),_c('div',[_c('el-rate',{attrs:{"disabled":"","show-score":"","text-color":"#ff9900","score-template":`${_vm.info.score}`},model:{value:(_vm.info.score),callback:function ($$v) {_vm.$set(_vm.info, "score", $$v)},expression:"info.score"}})],1),_c('div',{staticClass:"min_price"},[_vm._v("$"+_vm._s(_vm.price))]),_c('div',{staticClass:"pro_sku"},_vm._l((_vm.sku.specifications),function(item,index){return _c('div',{key:index,staticClass:"pro_sku_item"},[_c('div',{staticClass:"pro_sku_itemkey"},[_vm._v(_vm._s(item.skuPropertyName)+":")]),_c('div',{staticClass:"pro_sku_itemvalue"},_vm._l((item.skuPropertyValues),function(items,indexs){return _c('div',{key:indexs,staticClass:"pro_sku_itemselect",class:{
                active: _vm.active[index].active == indexs,
                checked: items.checked == false,
              },on:{"click":function($event){return _vm.changeselect(
                  index,
                  indexs,
                  item.skuPropertyId,
                  items.propertyValueIdLong
                )}}},[_vm._v(" "+_vm._s(items.propertyValueDisplayName)+" ")])}),0)])}),0)])]),_c('div',{staticClass:"Productdetails_footer"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.description)}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }