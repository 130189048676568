import { getAxios } from '@/plugins/axios'

const axios = getAxios()

// 获取热门分类
export const getProducts = (shop) => {
  return axios.get('/api/getProducts?shop='+shop)
}
// 获取商品列表
// export const gethomeProducts = (params) => {
//   return axios.get("/api/shopify/getLinkbuyProductList", { params });
// };
export const gethomeProducts = (params) => {
  return axios.get("/api/products", { params });
};
export const accessToken = (code,shop) => {
  return axios.get('/api/accessToken?code='+code+'&shop='+shop)
}
// 获取店铺商品接口
export const shopifyproduct = (data) => {
  return axios.post('/api/shopify/product', data)
}
// 获取shopify分类接口
export const shopifycategory = (data) => {
  return axios.post('/api/shopify/category', data)
}
// 获取shopify分类接口
export const getCategoryIndex = (data) => {
  return axios.post('/api/getCategoryIndex', data)
}
// 获取shopify分类接口
export const addProduct = (data) => {
  return axios.post('/api/ShopifyAddProduct', data)
}
// 获取shopify分类接口
export const apitask = (data) => {
  return axios.post('/api/shopify/task', data)
}
// 获取shopify分类接口
export const shopifyshop = (data) => {
  return axios.post('/api/shopify/shop', data)
}
// 获取模板url
export const getTemplate = (data) => {
  return axios.post('/api/shopify/getTemplate', data)
}
// 获取模板url
export const createTaskSthree = (data) => {
  return axios.post('/api/shopify/createTaskSthree', data)
}
// 登录
export const businessLogin = (data) => {
  return axios.post('/api/businessLogin', data)
}
// 订单
export const borderquery = (data) => {
  return axios.post('/api/border/query', data)
}
// b段一件发货
export const getbordersend = (data) => {
  return axios.post('/api/shopify/business_send', data)
}
export const shopifysend = (data) => {
  return axios.post('/api/shopify/send', data)
}
// 跳转
export const getpaypalpay = (data) => {
  return axios.post('/api/paypal/pay', data)
}
// 获取首页分类
export const getCathome = () => {
  return axios.get("/api/getCategoryIndex");
};
// 获取商品详情
export const getProductById = (id) => {
  return axios.get(`/api/products/${id}`);
};
// 结果
export const getnotifyPay = (data) => {
  return axios.post("/api/notifyPay/", data);
};