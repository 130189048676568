<template>
  <div class="Product_box">
    <div class="Product_head">
      <div
        class="Product_item"
        v-for="(item, index) in categories"
        :key="index"
        :class="{ active: active == index }"
        @click="clickcategories(index, item.id)"
        :ref="'category_' + index"
      >
        <img
          :src="item.big_image"
          alt=""
        />
        <span>{{ item.title }}</span>
      </div>
    </div>
    <div class="Product_con">
      <div
        class="Product_conhead"
        v-if="active > -1"
      >
        {{ categories[active].title }}
      </div>
      <div class="Product_conall">
        <div
          class="Product_conItem"
          v-for="(item, index) in products"
          :key="index"
          @click="godetails(item.id)"
        >
          <img
            class="Product_img"
            :src="item.images"
            alt=""
          />
          <div class="product-box">
            <div class="product-card__title">{{ item.name }}</div>
            <div class="product-card__title1">
              {{ item.sold }}+ vendido recentemente
            </div>
            <div class="product-card__price">
              <!-- <span class="product-card__present"> 123<s>.123 </s> </span> -->
              <div
                class="product-card__present"
                style=""
              >
                <span class="currency">$</span>{{ item.skus.min_price2 }}
              </div>
            </div>
            <!-- <div class="add"> Add to product list</div>
            <div class="Delete"> Delete to product list</div> -->
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      v-if="count > 0"
      class="pagination pagination1"
      :small="isSmall"
      background
      layout="prev, pager, next"
      :total="count"
      :page-size="15"
      @current-change="currentchange"
    >
    </el-pagination>
  </div>
</template>
<script>
import { getCathome, gethomeProducts } from "@/api";
export default {
  name:'Product',
  data() {
    return {
      isSmall: window.innerWidth < 768,
      active: -1,
      categories: [],
      products: [],
      count: 0,
      ssinfo: {},
    };
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  computed: {},
  watch: {
    id: {
    handler(newId) {
      if(newId!=''){
        this.ssinfo.cat_id = newId;
        this.initData(); // 初始化数据
      }
    },
    immediate: true, // 立即触发一次
  },
  },
  
  mounted() {
    // if(this.$route.query.cat_id){
    //   this.ssinfo.cat_id = this.$route.query.cat_id;
    // }
    this.initData();
    window.addEventListener("resize", this.handleResize); // 监听窗口大小变化
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize); // 清除监听器
  },
  created() {},
  methods: {
    handleResize() {
      this.isSmall = window.innerWidth < 768; // 如果宽度小于 768，则设置 isSmall 为 true
    },
    currentchange(e) {
      console.log(e);
      this.ssinfo.page = e;
      this.upData();
    },
    clickcategories(index, id) {
      this.active = index;
      this.ssinfo.page = 1;
      this.ssinfo.cat_id = id;
      this.upData();
      // 滚动到选中的 `Product_item`
      this.$nextTick(() => {
        const categoryElement = this.$refs[`category_${index}`];
        if (categoryElement && categoryElement[0]) {
          categoryElement[0].scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center"   // 水平滚动到中间位置
          });

        }
      });
    },
    async initData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      try {
        const { info } = await getCathome();
        const {
          info: { data, total },
        } = await gethomeProducts(this.ssinfo);
        this.categories = [];

        info.request.forEach((element) => {
          element.allGrandchildren.forEach((item) => {
            this.categories.push(item);
          });
        });
        if (this.ssinfo.cat_id) {
          const index = this.categories.findIndex(
            (item) => item.id == this.ssinfo.cat_id
          );
          this.active = index;
          this.$nextTick(() => {
            const categoryElement = this.$refs[`category_${index}`];
            if (categoryElement && categoryElement[0]) {
              categoryElement[0].scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center"   // 水平滚动到中间位置
              });

            }
          });
        }
        for (const item of data) {
          if (item != null) {
            item.images = JSON.parse(item.images).image250PathList[0];
          }
        }
        this.products = data;
        console.log(this.products, 123);
        this.count = total;
      } catch (error) {
        console.log(123);
      } finally {
        console.log(123);
        loading.close();
      }
    },
    godetails(id) {
      console.log(id);
      this.$router.push({ name: "Productdetails", query: { id: id } });
    },
    async upData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      try {
        const {
          info: { data, total },
        } = await gethomeProducts(this.ssinfo);
        for (const item of data) {
          if (item != null) {
            item.images = JSON.parse(item.images).image250PathList[0];
          }
        }
        this.products = data;
        this.count = total;
      } catch (error) {
        console.log(123);
      } finally {
        loading.close();
        console.log(123);
      }
    },
  },
};
</script>
<style scoped>
.Product_box {
  padding: 0 24px 18px;
  background: #f1f1f1;
  min-height: 100vh;
}
.Product_head {
  display: flex;
  flex-wrap: nowrap;
  padding-top: 16px;
  overflow: auto;
  padding-bottom: 5px;
}
.Product_head::-webkit-scrollbar {
  height: 5px;
}

.Product_head::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.Product_head::-webkit-scrollbar-thumb:hover {
  background-color: rgb(48, 48, 48);
}

.Product_item {
  margin-right: 14px;
  height: 40px;
  padding: 0 16px;
  align-items: center;
  display: flex;
  white-space: nowrap;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid rgb(48, 48, 48);
  color: rgb(48, 48, 48);
  font-weight: 550;
  line-height: 20px;
  cursor: pointer;
}
.Product_item:hover {
  border-color: #fff;
  background: rgb(48, 48, 48);
  color: #fff;
  transition: 0.3s;
}
.Product_item.active {
  border-color: #fff;
  background: rgb(48, 48, 48);
  color: #fff;
  transition: 0.3s;
}

.Product_item img {
  width: 20px;
  height: 20px;
  border-radius: 8px;
  margin-right: 5px;
}
.Product_conhead {
  margin: 20px 0;
  font-weight: 450;
  font-size: 18px;
  line-height: 20px;
  color: rgb(48, 48, 48);
}
.product-box {
  padding: 6px 8px 11px 8px;
}
.product-card__title {
  line-height: 14px;
  color: rgb(0, 0, 0);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-regular;
  font-weight: bold;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.product-card__title1 {
  color: rgb(204, 110, 0);
  line-height: 14px;
  font-size: 11px;
  margin: 6px 0;
}
.Product_img {
  object-fit: contain;
  width: 100%;
  max-height: 300px;
  min-height: 300px;
  border-radius: 4px;
}
.product-card__present {
  color: rgb(32, 35, 51);
  font-weight: 700;
  font-size: 13px;
}
.Product_conall {
  display: flex;
  flex-wrap: wrap;
}
.Product_conItem {
  margin: 7px;
  width: 19%;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}
.add {
  width: 100%;
  padding: 6px 12px;
  border-radius: 8px;
  background-color: rgb(48, 48, 48);
  color: #fff;
  font-size: 13px;
  font-weight: 650;
  text-align: center;
  margin-top: 8px;
}
.Delete {
  width: 100%;
  padding: 6px 12px;
  border-radius: 8px;
  background-color: red;
  color: #fff;
  font-size: 13px;
  font-weight: 650;
  margin-top: 8px;
  text-align: center;
}
.pagination {
  margin-top: 6px;
  text-align: right;
}

@media (max-width: 1468px) {
  .Product_conItem {
    width: 22%;
  }
}
@media (max-width: 1024px) {
  .Product_conItem {
    width: 30%;
  }
  .Product_box {
    padding: 0 12px;
  }
}
@media (max-width:762px) {
  .Product_conItem {
    width: 45%;
  }
}
@media (max-width: 461px) {
  .Product_conItem {
    width: 100%;
  }
}
</style>
