<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link>
      <router-link to="/ProductList">Products</router-link>
      <router-link to="/ProductList/recommendations">recommendations</router-link>
    </nav> -->
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
      <!-- Shopify Admin 侧边栏导航菜单 -->
    <ui-nav-menu style="display: none;">
      <a href="/" rel="home">Home</a>
      <!-- <a href="/Login">Login</a> -->
      <a href="/ProductSet">Products</a>
      <!-- <a href="/Productdetails">Productdetails</a> -->
      <a href="/ProductOrders">Product Orders</a>
      <a href="/Product">Find Products</a>
    </ui-nav-menu>
    
    <keep-alive include="Product,ProductSet">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>

export default {
  name: 'App',
  
}
</script>

<style>
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
</style>
