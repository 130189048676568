<template>
  <div class="Productdetails_box">
    <div
      class="goout"
      @click="goout"
    >
      <i class="el-icon-back"></i>
      Find Products
    </div>
    <div class="Productdetails_head">
      <div class="Productdetails_headleft">
        <div class="block">
          <el-carousel
            indicator-position="none"
            style="
              width: 300px;
              border: 1px solid rgb(233, 233, 233);
              border-radius: 5px;
            "
          >
            <el-carousel-item
              v-for="(item, index) in info.images"
              :key="index"
            >
              <el-image
                :src="item"
                fit="cover"
                style="width: 300px; height: 300px; border-radius: 5px"
              >
                <div
                  slot="error"
                  class="image-slot"
                >
                  fail to load
                </div>
              </el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="Productdetails_headright">
        <div class="Productdetails_headrighttit">
          {{ info.name }}
        </div>
        <div>
          <el-rate
            v-model="info.score"
            disabled
            show-score
            text-color="#ff9900"
            :score-template="`${info.score}`"
          >
          </el-rate>
        </div>
        <!-- <div class="min_price">${{ info?.skus?.min_price }}</div> -->
        <div class="min_price">${{ price }}</div>
        <div class="pro_sku">
          <div
            class="pro_sku_item"
            v-for="(item, index) in sku.specifications"
            :key="index"
          >
            <div class="pro_sku_itemkey">{{ item.skuPropertyName }}:</div>
            <div class="pro_sku_itemvalue">
              <div
                class="pro_sku_itemselect"
                :class="{
                  active: active[index].active == indexs,
                  checked: items.checked == false,
                }"
                @click="
                  changeselect(
                    index,
                    indexs,
                    item.skuPropertyId,
                    items.propertyValueIdLong
                  )
                "
                v-for="(items, indexs) in item.skuPropertyValues"
                :key="indexs"
              >
                {{ items.propertyValueDisplayName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Productdetails_footer">
      <div v-html="description"></div>
    </div>
  </div>
</template>
<script>
import { getProductById } from "@/api";
export default {
  name: "Productdetails",
  data() {
    return {
      id: "",
      info: {},
      sku: [],
      active: [],
      price: 0,
      description: "",
    };
  },
  computed: {},
  async mounted() {
    this.id = this.$route.query.id;
    await this.initData();
    
  },

  methods: {
    goout() {
      // console.log(123)
      this.$router.go(-1); // 路由向前跳转一步
    },
    // selectfun(sku) {
    //   // 初始化每个项的 checked 状态为 false
    //   sku.specifications.forEach((item) => {
    //     item.skuPropertyValues.forEach((value) => {
    //       value.checked = false; // 初始状态设置为 false
    //     });
    //   });

    //   Object.keys(sku.price).forEach((key) => {
    //     const splitKeyArray = key.split(","); // 获取当前的 splitKeyArray

    //     sku.specifications.forEach((item) => {
    //       let self = splitKeyArray.indexOf(String(item.skuPropertyId));
    //       if (self !== -1) {
    //         item.skuPropertyValues.forEach((value) => {
    //           console.log(value.propertyValueIdLong, splitKeyArray, "aiwjhfi");

    //           // 检查当前 propertyValueIdLong 是否在 splitKeyArray 中
    //           if (
    //             splitKeyArray.indexOf(String(value.propertyValueIdLong)) !== -1
    //           ) {
    //             value.checked = true; // 在数组中，设置为 true
    //           }
    //         });
    //       }
    //     });
    //   });

    //   return sku; // 返回修改后的 sku
    // },

    async initData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.active = [];
      const { info } = await getProductById(this.id);
      info.images = JSON.parse(info.images).image640PathList;
      info.score = Number(info.score);
      this.info = info;
      for (let i = 0; i < this.info.skus.specifications.length; i++) {
        this.active.push({ active: -1, id: [] });
      }
      this.sku = info.skus;
      this.price = info.skus.min_price2;
      this.description = this.descriptionFun(info.description);
      loading.close();
    },
    // changeselect(index, indexs, skuPropertyId, propertyValueIdLong) {
    //   this.active[index].active = indexs;
    //   this.active[index].id = [
    //     String(skuPropertyId),
    //     String(propertyValueIdLong),
    //   ];
    //   console.log(skuPropertyId, "day1");
    //   // 获取每个对象中的键
    //   const resultIdsSet = new Set();
    //   Object.keys(this.sku.price).forEach(() => {
    //     // const splitKeyArray = key.split(","); // 将 key 分割为数组
    //     // console.log(splitKeyArray); // 输出键
    //     this.active.forEach((item) => {
    //       // console.log(item,index)
    //       if (item.id.length != 0) {
    //         // item.id.forEach((items) => {
    //         resultIdsSet.add(item.id);
    //         // });
    //       }
    //     });
    //     console.log(resultIdsSet);
    //     // console.log(resultIdsSet,1)
    //     // console.log(splitKeyArray)
    //     // 将 resultIdsSet 转换为数组
    //     // const resultIdsArray = Array.from(resultIdsSet);

    //     // // 检查 splitKeyArray 是否完全包含 resultIdsArray 中的所有元素
    //     // const allIdsMatch = resultIdsArray.every((id) =>
    //     //   splitKeyArray.includes(id)
    //     // );

    //     // if (allIdsMatch) {
    //     //   // 如果完全包含，输出 splitKeyArray
    //     //   // console.log(this.sku.specifications)
    //     //   console.log(splitKeyArray,'符合'); // 打印满足条件的 splitKeyArray
    //     // }else{
    //     //   console.log(splitKeyArray,'不符合')
    //     // }
    //     // if (!allIdsMatch) {
    //     //   console.log(splitKeyArray); // 打印不满足条件的 splitKeyArray
    //     // }
    //   });
    // },
    changeselect(index, indexs, skuPropertyId, propertyValueIdLong) {
      // 更新 this.active[index] 的状态
      this.active[index].active = indexs;
      this.active[index].id = [skuPropertyId, propertyValueIdLong];

      // 检查所有 this.active 中的 id 是否都有长度
      const allIdsHaveLength = this.active.every((item) => item.id.length > 0);

      // 如果所有 id 长度都大于 0，则执行逻辑
      if (allIdsHaveLength) {
        let id = [];
        this.active.forEach((item) => {
          item.id.forEach((items) => {
            id.push(items);
          });
        });
        let idIndex = id.join(",");
        // console.log(this.sku.price[idIndex].price, "day1");
        this.price = this.sku.price[idIndex].price;
      } else {
        console.log("Not all ids have length > 0");
      }
    },
    descriptionFun(description) {
      description = description.replace(
        /<img([^>]*)style="[^"]*"([^>]*)>/gi,
        '<img$1style="width: 100%; vertical-align: middle;height: auto;display: block;"$2>'
      ); // 修改已有的 style
      description = description.replace(
        /<img((?!style)[^>]*)>/gi,
        '<img$1 style="width: 100%; vertical-align: middle;height: auto;display: block;">'
      );
      // 处理 <p> 标签
      description = description.replace(
        /<p([^>]*)style="[^"]*"([^>]*)>/gi,
        '<p$1style="width: 100%; vertical-align: middle;text-align: center;"$2>'
      ); // 修改已有的 style 的 p 标签
      description = description.replace(
        /<p((?!style)[^>]*)>/gi,
        '<p$1 style="width: 100%; vertical-align: middle;text-align: center;">'
      ); // 为没有 style 属性的 p 标签添加 style
      // 处理 <div> 标签
      description = description.replace(
        /<div([^>]*)style="[^"]*"([^>]*)>/gi,
        '<div$1style="width: 100%;text-align: center;"$2>'
      ); // 修改已有的 style 的 div 标签
      description = description.replace(
        /<div((?!style)[^>]*)>/gi,
        '<div$1 style="width: 100%;text-align: center;">'
      );
      description = description.replace(/<a[^>]*>.*?<\/a>/gis, "");
      // 将 <img> 标签中的内容替换为占位符
      const imgTags = [];
      description = description.replace(/<img[^>]*>/gi, function (match) {
        imgTags.push(match);
        return `__IMG_${imgTags.length - 1}__`;
      });

      // 检测并替换所有网址为空
      description = description.replace(/https?:\/\/[^\s]+/gi, "");

      // 将占位符替换回 <img> 标签
      description = description.replace(
        /__IMG_(\d+)__/gi,
        function (match, index) {
          return imgTags[index];
        }
      );
      return description;
    },
  },
};
</script>
<style scoped>
.Productdetails_box {
  padding: 0 24px 18px;
  background: #f1f1f1;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
::v-deep .image-slot {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #c0c4cc;
  vertical-align: middle;
  font-size: 14px;
}
.Productdetails_head {
  width: 100%;
  max-width: 950px;
  padding: 16px;
  background-color: #fff;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 5px;
  display: flex;
}
.Productdetails_headright {
  margin-left: 16px;
}
.Productdetails_headrighttit {
  margin-bottom: 8px;
  color: rgb(48, 48, 48);
  font-size: 14px;
  font-weight: 650;
}
.min_price {
  color: rgb(32, 35, 51);
  font-weight: 500;
  font-size: 18px;
  margin-top: 10px;
}
.pro_sku {
  margin-top: 12px;
}
.pro_sku_item {
  display: flex;
  margin-bottom: 8px;
}
.pro_sku_itemkey {
  min-width: 120px;
  color: rgb(48, 48, 48);
  font-size: 13px;
  font-weight: 450;
}
.pro_sku_itemvalue {
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  max-width: 420px;
}
.pro_sku_itemselect {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  border: 1px solid #eee;
  border-radius: 4px;
  cursor: pointer;
  display: -webkit-box !important;
  line-height: 30px;
  margin-bottom: 5px;
  margin-right: 10px;
  max-width: 200px;
  overflow: hidden;
  padding: 0 8px;
  text-align: center;
  text-overflow: ellipsis;
  word-break: break-word;
  color: rgb(48, 48, 48);
  font-size: 13px;
  font-weight: 450;
}
.pro_sku_itemselect.active {
  border: 1px solid #f5520c;
}
.pro_sku_itemselect.checked {
  border: 1px solid rgb(200, 201, 204);
  color: rgb(200, 201, 204);
}
.pro_sku_itemselect:hover {
  border: 1px solid #f5520c;
}
.Productdetails_footer {
  margin-top: 20px;
  background-color: #fff;
  border: 1px solid rgb(233, 233, 233);
  padding: 16px;
  border-radius: 5px;
  max-width: 950px;
  width: 100%;
}
.goout {
  color: rgb(48, 48, 48);
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  max-width: 950px;
  padding: 24px 0;
  cursor: pointer;
}
@media (max-width: 715px) {
  .Productdetails_head {
    flex-wrap: wrap;
  }
  .Productdetails_headleft {
    width: 100%;
  }
  .block {
    display: flex;
    justify-content: center;
  }
  .Productdetails_headright {
    margin-top: 10px;
  }
  .Productdetails_box {
    padding: 0 12px 18px;
  }
}
</style>
