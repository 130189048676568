import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router"; // 引入路由配置
import { getProducts, accessToken } from "@/api";
Vue.config.productionTip = false;
Vue.use(ElementUI);

new Vue({
  router, // 使用路由
  created: async function () {
    // localStorage.setItem("shopName", "linkbuyaaa.myshopify.com");
    // // 添加条件判断来控制是否中断
    // const shouldReturn = true; // 这里可以根据你的逻辑来设置条件
    // if (shouldReturn) {
    //   return; // 根据条件返回，阻止后续代码执行
    // }
    console.log(window.location.href,'如果是指定路径，直接返回，跳过后续操作')
    if (window.location.href.includes("https://a.wa-proxy.cc/paysucess")) {
      console.log("当前页面为支付成功页面，不执行后续操作");
      return; // 如果包含，直接返回，跳过后续操作
    }
    try {
     
      // const shopifytoken = localStorage.getItem("shopifytoken");
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      // 获取完整路径，例如 '/store/linkbuyaaa/apps/linkbuy'
      const shopName = urlParams.get("shop");
      localStorage.setItem("shopName", shopName);

      // if (!shopifytoken) {
      if (code) {
        await accessToken(code, shopName);
        // if (response.code == 1) {
        //   localStorage.setItem("shopifytoken", response.api_token);
        // }
      } else {
        const res = await getProducts(shopName);
        console.log(res);
        if (res.installUrl) {
          // 封印
          window.top.location.href = res.installUrl;
        }
      }
      // }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  },
  render: (h) => h(App),
}).$mount("#app");
