<template>
  <div class="login_box">
    <div class="login_con">
      <div class="login_submit">
        <div class="login_title">Log in to your LinkBuy</div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
        >
          <el-form-item
            label="Email"
            prop="email"
          >
            <el-input
              size="medium "
              v-model="ruleForm.email"
              type="text"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="Password"
            prop="password"
          >
            <el-input
              size="medium"
              show-password
              v-model="ruleForm.password"
            ></el-input>
          </el-form-item>
          <el-form-item style="margin-bottom: 10px">
            <el-button
              class="loginbutton"
              size="medium "
              type="primary"
              @click="submitForm('ruleForm')"
              >LOG IN AND AUTHORIZE</el-button
            >
          </el-form-item>
        </el-form>
        <div
          class="Linkbuyres"
          @click="risger(2)"
          v-if="type == 1"
        >
          Sign up for LinkBuy
          <img
            src="../assets/share.png"
            alt=""
          />
        </div>
        <div
          class="Linkbuyres"
          @click="risger(1)"
          v-else
        >
          Login in for LinkBuy
          <img
            src="../assets/share.png"
            alt=""
          />
        </div>
        <!-- <div class="line-hr">
          <span>or</span>
        </div>
        <div class="login_authorized">
          <div class="tree_box">
            <svg
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 1C33.598 1 43 10.402 43 22C43 33.598 33.598 43 22 43C10.402 43 1 33.598 1 22C1 10.402 10.402 1 22 1Z"
                fill="white"
                stroke="#E5E5E5"
                stroke-width="2"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M33.3018 19.8182H22V24.46H28.4582C28.18 25.96 27.3345 27.2309 26.0636 28.0818V31.0927H29.9418C32.2109 29.0036 33.52 25.9273 33.52 22.2727C33.52 21.4218 33.4436 20.6036 33.3018 19.8182Z"
                fill="#4285F4"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22 34C25.24 34 27.9564 32.9255 29.9418 31.0928L26.0636 28.0818C24.9891 28.8018 23.6145 29.2273 22 29.2273C18.8745 29.2273 16.2291 27.1164 15.2855 24.28H11.2764V27.3891C13.2509 31.3109 17.3091 34 22 34Z"
                fill="#34A853"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.2855 24.28C15.0455 23.56 14.9091 22.7909 14.9091 22C14.9091 21.2091 15.0455 20.44 15.2855 19.72V16.6109H11.2764C10.4636 18.2309 10 20.0636 10 22C10 23.9364 10.4636 25.7691 11.2764 27.3891L15.2855 24.28Z"
                fill="#FBBC05"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22 14.7727C23.7618 14.7727 25.3436 15.3782 26.5873 16.5673L30.0291 13.1255C27.9509 11.1891 25.2345 10 22 10C17.3091 10 13.2509 12.6891 11.2764 16.6109L15.2855 19.72C16.2291 16.8836 18.8745 14.7727 22 14.7727Z"
                fill="#EA4335"
              />
            </svg>
            <span>Continue with Google</span>
            <div
              id="g_id_signin"
              class="g_id_signin"
              style="opacity: 0"
            />
          </div>
          <div class="tree_box">
            <fb-signin-button
              class="fbbook"
              :params="fbSignInParams"
              @success="onSignInSuccess"
            />
            <svg
              t="1718864838175"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="7974"
              width="200"
              height="200"
            >
              <path
                d="M588.288 998.912V651.776h114.176l17.408-137.728H588.288v-88.064c0-22.528 4.608-38.912 13.312-50.176 9.216-11.264 26.112-16.896 52.224-16.896h70.656V235.52c-24.064-3.584-58.368-5.632-102.4-5.632-51.712 0-93.184 15.872-124.416 47.616s-46.592 76.8-46.592 134.656v101.376H336.384v137.728H450.56v349.696C207.36 970.752 18.944 763.392 18.944 512c0-272.384 220.672-493.568 493.568-493.568S1006.08 239.104 1006.08 512c0 246.784-181.248 450.56-417.792 486.912z m-75.264 6.656H512h1.024z"
                fill="#1877f2"
                p-id="7975"
              />
            </svg>
            <span>Continue with Facebook</span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { businessLogin } from "@/api";
// import Cookies from "js-cookie";

export default {
  data() {
    return {
      type:1,
      input: "",
      ruleForm: {
        email: "",
        password: "",
      },
      rules: {
        email: [
          { required: true, message: "Please enter email", trigger: "blur" },
          {
            type: "email",
            message: "Please enter a valid email address",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please fill in the password",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    risger(type) {
      console.log(this.type)
  
      this.type=type
      // const width = 375; // 设置移动端窗口宽度
      // const height = 667; // 设置移动端窗口高度

      // // 居中显示新窗口
      // const left = (window.innerWidth - width) / 2;
      // const top = (window.innerHeight - height) / 2;

      // // 打开新窗口，设置大小、位置，去掉可调整大小功能
      // window.open(
      //   "https://store.2p44.com/register?shop=" +
      //     localStorage.getItem("shopName"), // 你想打开的URL
      //   "_blank", // 打开方式，_blank表示新窗口
      //   `width=${width},height=${height},left=${left},top=${top},resizable=no,scrollbars=no`
      // );
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          businessLogin({
            email: this.ruleForm.email,
            password: this.ruleForm.password,
          }).then((res) => {
            console.log(res);
            if (res.code == 1) {
              if (res.paypalmeurl == 1) {
                localStorage.setItem("token", res.api_token);
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                const urlParams = new URLSearchParams(window.location.search);
                const shop = urlParams.get('shop')
                this.$router.push({ path: '/',query:{shop:shop} }); 
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
                // Cookies.set("vue_mall_template_token", res.api_token, {
                //   expires: 7,
                //   domain: ".2p44.com",
                //   path: "/",
                //   secure: true,
                // });
                // Cookies.set("name", res.email, {
                //   expires: 7,
                //   domain: ".2p44.com",
                //   path: "/",
                //   secure: true,
                // });
                const width = 375; // 设置移动端窗口宽度
                const height = 667; // 设置移动端窗口高度

                // 居中显示新窗口
                const left = (window.innerWidth - width) / 2;
                const top = (window.innerHeight - height) / 2;
                setTimeout(() => {
                  // 打开新窗口，设置大小、位置，去掉可调整大小功能
                  window.open(
                    "https://store.2p44.com/payment?token_shopify="+res.api_token+'&email_shopify='+ res.user, // 你想打开的URL
                    "_blank", // 打开方式，_blank表示新窗口
                    `width=${width},height=${height},left=${left},top=${top},resizable=no,scrollbars=no`
                  );
                }, 1500);
              }
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.login_title {
  color: rgba(0, 0, 0, 0.88);
  font-family: OpenSans-Bold, OpenSans;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 12px;
}
.login_con {
  background: url("../assets/loginbj.png") no-repeat 50%;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login_submit {
  background-color: #fff;
  box-sizing: border-box;
  height: 100%;
  padding: 48px 25px 30px 31px;
  width: 400px;
  border-radius: 12px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.08),
    0 3px 14px 2px rgba(0, 0, 0, 0.04), 0 8px 10px 1px rgba(0, 0, 0, 0.06);
}
/* .login_input {
  display: flex;
  align-items: center;
}
.login_input {
} */
::v-deep .el-form-item__label {
  line-height: 15px;
  font-size: 12px;
}
.el-button {
  background-color: rgb(6, 6, 6);
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  font-family: OpenSans-SemiBold, OpenSans;
}
.Linkbuyres {
  color: #5c96db;
  font-size: 12px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  cursor: pointer;
}
.Linkbuyres img {
  width: 20px;
  margin-left: 5px;
}
.login_line {
  display: flex;
  align-items: center;
}

.line-hr {
  margin-bottom: 16px;
  display: flex;
  color: #959595;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.line-hr span {
  padding: 0 12px;
}
.line-hr:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #e5e5e5;
}
.line-hr::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #e5e5e5;
}
.login_authorized {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.fb-signin-button {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: #4267b2;
  color: #fff;
}
.g_id_signin {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  left: 0;
  top: 0;
}
.google_box {
  position: relative;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tree_box {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #e5e5e5;
  height: 44px;
  margin-bottom: 12px;
  padding: 0 24px;
  position: relative;
}
.tree_box span {
  font-size: 14px;
  color: rgb(34, 34, 34);
  font-weight: 400;
}
.tree_box svg {
  width: 30px;
  height: 30px;
  margin-left: 27px;
  margin-right: 16px;
}
@media (max-width: 515px) {
  .login_submit {
    padding: 24px 20px 15px 20px;
    width: 300px;
  }
  .login_title {
    font-size: 20px;
    line-height: 36px;
  }
  .tree_box span {
    font-size: 12px;
  }
}
</style>
