<template>
  <div></div>
</template>

<script>
import { getnotifyPay } from "@/api";
export default {
  data() {
    return {
      params: {},
    };
  },
  mounted() {
    this.params.success = this.$route.query.success;
    this.params.token = this.$route.query.token;
    this.params.id = this.$route.query.id;

    if (this.params.success == "true") {
      this.params.paymentId = this.$route.query.paymentId;
      this.params.PayerID = this.$route.query.PayerID;
    }
    this.getend();
    //   if (window.opener) {
    //     // 确保父页面存在
    //     window.opener.postMessage({ type: "paymentSuccess" }, "*"); // 向父页面发送消息
    //     window.close(); // 关闭当前支付窗口
    //   } else {
    //     console.warn("父页面不存在，无法发送消息并关闭窗口。");
    //   }
  },

  methods: {
    getend() {
      this.loadingbox = true;
      getnotifyPay(this.params)
        .then(() => {})
        .finally(() => {
          if (window.opener) {
            // 确保父页面存在
            window.opener.postMessage({ type: "paymentSuccess" }, "*"); // 向父页面发送消息
            window.close(); // 关闭当前支付窗口
          } else {
            console.warn("父页面不存在，无法发送消息并关闭窗口。");
          }
        });
    },
  },
};
</script>

<style></style>
