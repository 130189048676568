<template>
  <div>
    <h1>Products</h1>
    <ul v-if="products.length">
      <li v-for="product in products" :key="product.id">{{ product.title }}</li>
    </ul>
    <p v-else>No products found.</p>
    <button @click="fetchProducts">Load Products</button>
    <router-view></router-view> <!-- 子路由会渲染在这里 -->
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ProductList',
  data() {
    return {
      products: [],
    };
  },
  methods: {
    async fetchProducts() {
      const shopName = 'linkbuyaaa'; // 替换为你的商店名称
      const accessToken = 'your-access-token'; // 使用你的访问令牌

      try {
        const response = await axios.get(`https://${shopName}.myshopify.com/admin/api/2024-01/products.json`, {
          headers: {
            'X-Shopify-Access-Token': accessToken,
            'Content-Type': 'application/json'
          }
        });
        this.products = response.data.products; // 更新产品数据
      } catch (error) {
        console.error('Error fetching products:', error.response ? error.response.data : error.message);
      }
    }
  }
};
</script>

<style>
/* 添加样式 */
</style>
