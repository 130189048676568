<template>
  <div>
    recommendations
  </div>
</template>

<script>

export default {
  name: 'recommendations',

}
</script>

<style>
/* 添加样式 */
</style>
